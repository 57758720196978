import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

import { getMaybeUser, getUser } from "@/common/services/user";
import { useLogout } from "@/routes/logout/logout";

import { useTokenUpdate } from "../providers/token-provider";
import { useToggle } from "./use-toggle";

export const useUserRefresh = () => {
  const [pending, updateToken] = useTokenUpdate();
  const { user, isLoading, error } = useAuth0();
  const logout = useLogout();
  const [userState, setUserState] = React.useState<"valid" | "invalid">();
  const refreshable = useToggle(true);

  const setValidUserState = () => {
    setUserState("valid");
  };

  const setInvalidUserState = () => {
    setUserState("invalid");
  };

  React.useEffect(() => {
    if (isLoading || !refreshable.on) {
      return;
    }

    if (userState === "valid") {
      return;
    }

    if (pending) {
      return;
    }

    if (error || !user) {
      setInvalidUserState();
      return;
    }

    try {
      getMaybeUser(user);
      getUser(user);
      setValidUserState();
    } catch (e) {
      refreshable.setOff();
      updateToken()
        .then(setValidUserState)
        .catch(() => {
          setInvalidUserState();
          return logout();
        });
    }
  }, [
    userState,
    pending,
    error,
    isLoading,
    logout,
    updateToken,
    user,
    refreshable,
  ]);

  return {
    userState,
    user,
  };
};
