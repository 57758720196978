import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";

import { ErrorPage } from "@/common/components/error-page/error-page";
import { Features } from "@/common/providers/feature-provider";
import { PlanSchema } from "@/common/services/user";
import {
  FeatureMode,
  getFeatureMode,
} from "@/domain/plan/feature-mode.service";
import { Nullable } from "@/types";

import { Anonymous } from "./anonymous/anonymous";
import { BlockedUser } from "./blocked-user/blocked-user";
import { Callback, path as callbackPath } from "./callback/callback";
import * as IncompleteUser from "./incomplete-user/incomplete-user";
import { Layout } from "./layout/layout";
import { Logout } from "./logout/logout";
import * as PurchasePrices from "./purchase-prices/purchase-prices";
import { path as quickTradingPath } from "./quick-trading/quick-trading";
import { NavigateToInitialPage, Root } from "./root/root";
import { routes as adminRoutes } from "./routes.admin";
import { getReportsRoutes, stockExchangeRoutes } from "./routes.basic";
import {
  getPurchasePricesRoutes,
  quickTradingRoutes,
  storagesRoutes,
  tradingRoutes,
  transactionsAndOrdersRoutes,
} from "./routes.trading";
import { LockedScreen } from "./shared/components/locked-screen/locked-screen";
import { path as stockExchangePath } from "./stock-exchange/stock-exchange";
import { Layout as SubscriptionLayout } from "./subscriptions/layout";
import { Subscribed } from "./subscriptions/subscribed/subscribed";
import { Unsubscribed } from "./subscriptions/unsubscribed/unsubscribed";

type RouteConfig = {
  path: string;
  routes: Record<FeatureMode, RouteObject[]>;
  feature: keyof Features;
};

const createIncompleteUserRouter = () =>
  createBrowserRouter([
    {
      path: IncompleteUser.path,
      element: <IncompleteUser.IncompleteUser />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "*",
      element: <Navigate to={"/" + IncompleteUser.path} />,
    },
  ]);

const createBlockedUserRouter = () =>
  createBrowserRouter([
    {
      path: "*",
      element: <BlockedUser />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
  ]);

const createAnonymousRouter = () =>
  createBrowserRouter([
    {
      path: "*",
      element: <Anonymous />,
    },
    {
      path: callbackPath,
      element: <Callback />,
    },
  ]);

// TODO import paths from subroutes
const featureRoutes: RouteConfig[] = [
  {
    path: "trades",
    feature: "TRADING",
    routes: {
      full: tradingRoutes,
      trial: [
        {
          element: <LockedScreen />,
          path: "trades/*",
        },
      ],
      "not-available": [],
    },
  },
  {
    path: "quick-trading",
    feature: "QUICK_TRADING",
    routes: {
      full: quickTradingRoutes,
      trial: [{ element: <LockedScreen />, path: "quick-trading/*" }],
      "not-available": [],
    },
  },
  {
    path: "purchase-prices",
    feature: "PURCHASE_PRICES",
    routes: {
      full: getPurchasePricesRoutes("full"),
      trial: getPurchasePricesRoutes("trial"),
      // FIXME get rid of this once the packages are released to every company
      "not-available": [
        {
          element: <Navigate to="/quick-trading" />,
          path: "purchase-prices/*",
        },
      ],
    },
  },
  {
    path: "storages",
    feature: "STORAGES",
    routes: {
      full: [storagesRoutes],
      trial: [{ element: <LockedScreen />, path: "storages/*" }],
      "not-available": [],
    },
  },
  {
    path: "transactions-and-orders",
    feature: "TRANSACTIONS_ORDERS",
    routes: {
      full: transactionsAndOrdersRoutes,
      trial: [{ element: <LockedScreen />, path: "transactions-and-orders/*" }],
      "not-available": [],
    },
  },
  {
    path: "stock-exchange",
    feature: "STOCK_EXCHANGE",
    routes: {
      full: stockExchangeRoutes,
      trial: [{ element: <LockedScreen />, path: "stock-exchange/*" }],
      "not-available": [],
    },
  },
  {
    path: "reports",
    feature: "MARKET_NEWS",
    routes: {
      full: getReportsRoutes("full"),
      trial: getReportsRoutes("trial"),
      "not-available": [],
    },
  },
];

const getFeatureRoutes = (
  featureConfig: RouteConfig,
  companyPlan: Nullable<PlanSchema["planId"]>,
  features: Features,
): RouteObject[] => {
  const mode = getFeatureMode(companyPlan, features[featureConfig.feature]);
  return featureConfig.routes[mode];
};

const getRoutes = (
  companyPlan: Nullable<PlanSchema["planId"]>,
  features: Features,
) => [
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/subscribed",
    element: (
      <Root>
        <SubscriptionLayout>
          <Subscribed />
        </SubscriptionLayout>
      </Root>
    ),
  },
  {
    path: "/unsubscribed",
    element: (
      <Root>
        <SubscriptionLayout>
          <Unsubscribed />
        </SubscriptionLayout>
      </Root>
    ),
  },
  {
    path: "/",
    element: (
      <Root>
        <NavigateToInitialPage
          page={resolveInitialPage(companyPlan, features)}
        />
      </Root>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        element: <Callback />,
        path: callbackPath,
      },
      {
        element: <Layout />,
        children: [
          {
            element: <LockedScreen />,
            path: "pricing",
          },
          ...featureRoutes.flatMap((config) =>
            getFeatureRoutes(config, companyPlan, features),
          ),
        ],
      },
    ],
  },
  // just to be sure user initially marked as incomplete never lands on incomplete path
  // when eventually marked as complete
  {
    path: IncompleteUser.path,
    element: <Navigate to="/" replace />,
  },
  ...adminRoutes(),
];

const resolveInitialPage = (
  companyPlan: Nullable<PlanSchema["planId"]>,
  features: Features,
) => {
  if (!companyPlan) {
    return stockExchangePath;
  }

  if (features.QUICK_TRADING?.planId === companyPlan) {
    return quickTradingPath;
  }

  if (features.PURCHASE_PRICES?.planId === companyPlan) {
    return PurchasePrices.path;
  }

  if (features.PURCHASE_PRICES?.mode === "TRIAL") {
    return PurchasePrices.path;
  }

  return stockExchangePath;
};

const createRouter = (
  companyPlan: Nullable<PlanSchema["planId"]>,
  features: Features,
) => createBrowserRouter(getRoutes(companyPlan, features));

export {
  createAnonymousRouter,
  createBlockedUserRouter,
  createIncompleteUserRouter,
  createRouter,
  getRoutes,
};
